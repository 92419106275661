<template>
  <el-dialog
    title="创建标签"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
  >
    <div>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="标签名称" prop="tag">
          <el-input v-model="form.tag" placeholder="标签名称"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitFun">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { addApi } from '@/api/mark/index'
export default {
  data() {
    return {
      dialogVisible: false,
      form: {},
      rules: {
        tag: [
          {
            required: true,
            message: '请输入标签名',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    initFun() {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.form = {}
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    submitFun() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          addApi(this.form).then(res => {
            // this.$message.success('提交成功！')
            this.dialogVisible = false
            this.$emit('updateFun')
          })
        }
      })
    }
  }
}
</script>
