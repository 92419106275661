import request from '@/plugins/axios'

export function listApi(params) {
  // 分页获取
  return request({
    url: '/mark/tags',
    method: 'get',
    params
  })
}
export function addApi(data) {
  // 新增
  return request({
    url: '/mark/tags',
    method: 'post',
    data
  })
}
export function get_or_createApi(data) {
  // 获取或新增标签
  return request({
    url: '/mark/tags:get_or_create',
    method: 'post',
    data
  })
}
export function detailApi(id) {
  // 获取指定标签
  return request({
    url: `/mark/tags/${id}`,
    method: 'get'
  })
}
export function delApi(id) {
  // 删除
  return request({
    url: `/mark/tags/${id}`,
    method: 'delete'
  })
}
export function all_tagApi(params) {
  // 获取所有标签
  return request({
    url: `/mark/tags:get_all`,
    method: 'get',
    params
  })
}
